import { Icon } from '@iconify/react';
import * as Sentry from '@sentry/nextjs';
import Link from 'next/link';
import { Trans, useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import MainLayout from '@/components/core/MainLayout';
import Page from '@/components/core/Page';

function Error({ err, statusCode, hasGetInitialPropsRun }: any) {
  const { t } = useTranslation('common');

  if (!hasGetInitialPropsRun && err) {
    // getInitialProps is not called in case of
    // https://github.com/vercel/next.js/issues/8592. As a workaround, we pass
    // err via _app.js so it can be captured
    Sentry.captureException(err);
  }

  return (
    <MainLayout>
      <Page title={statusCode ? 'Error ' + statusCode : 'Error'}>
        <div className="flex flex-col text-center items-center py-16 space-y-6">
          <Icon className="text-gray-200 text-[200px]" icon="mdi:emoticon-cry-outline" />
          <h2 className="text-6xl fonte-bold">{t('error-title')}</h2>
          <p className="text-xl">
            <Trans t={t} i18nKey={'error-body'} />
          </p>
          <Link href="/">{t('Home')}</Link>
        </div>
      </Page>
    </MainLayout>
  );
}

Error.getInitialProps = async (o: any) => {
  const { locales, locale, res, err, asPath } = o;
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;

  // ignore 404 error
  if (res?.statusCode !== 404) {
    if (err) {
      Sentry.captureException(err);
      // Flushing before returning is necessary if deploying to Vercel, see
      // https://vercel.com/docs/platform/limits#streaming-responses
      await Sentry.flush(2000);
    } else {
      // If this point is reached, getInitialProps was called without any
      // information about what the error might be. This is unexpected and may
      // indicate a bug introduced in Next.js, so record it in Sentry
      Sentry.captureException(
        // @ts-ignore
        new Error(`_error.js getInitialProps missing data at path: ${asPath}`),
      );
    }
  }

  try {
    return {
      err,
      statusCode,

      // Workaround for https://github.com/vercel/next.js/issues/8592, mark when
      // getInitialProps has run
      hasGetInitialPropsRun: true,

      ...(await serverSideTranslations(locale || locales?.[0] || 'en', ['home', 'common'])),
    };
  } catch (err) {
    Sentry.captureException(err);
    await Sentry.flush(2000);

    return { err, statusCode };
  }
};

export default Error;
